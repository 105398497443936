import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Toolbar, Typography, Menu, MenuItem, Box, Avatar, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, Divider } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HomeIcon from '@mui/icons-material/Home';
import { logout } from '../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.jpg'; // Import the logo from the assets folder
import { getAuth, signOut } from 'firebase/auth'; // Firebase authentication

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileOpen, setProfileOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const role = useSelector((state) => state.auth.role);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // const handleLogout = () => {
    
  //   dispatch(logout());
  //   handleMenuClose();
  //   navigate('/login');
  // };

  const handleLogout = async () => {
    try {
      // Sign out from Firebase
      const auth = getAuth();
      await signOut(auth);

      // Optionally clear specific cookies if necessary
      // document.cookie = "your_cookie_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      // Sign out from Google
      // const auth2 = window.gapi.auth2.getAuthInstance();
      // if (auth2) {
      //   await auth2.signOut();
      //   console.log('User signed out from Google.');
      // }

      // Dispatch logout action
      dispatch(logout());
      handleMenuClose();
      
      // Redirect to login page
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleHome = () => {
    navigate('/');
  };

  const handleProfileOpen = () => {
    setProfileOpen(true);
    handleMenuClose();
  };

  const handleProfileClose = () => {
    setProfileOpen(false);
  };

  const open = Boolean(anchorEl);

  return (
    <AppBar position="static" sx={{ backgroundColor: '#254e86', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="home" onClick={handleHome} sx={{ marginRight: 2 }}>
          <HomeIcon />
        </IconButton>
        <Box
          component="img"
          sx={{
            height: { xs: 40, sm: 60 }, // Responsive height
            marginRight: { xs: 2, sm: 4 }, // Responsive margin
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
          alt="Logo"
          src={logo} // Use the imported logo
        />
        <Typography variant="h5" component="div" sx={{ flexGrow: 1, fontWeight: 'bold', color: '#fff', fontSize: { xs: '1rem', sm: '1.5rem' }, letterSpacing: 1 }}>
          All-in-One Portal
        </Typography>
        {user && (
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleMenuOpen}>
            <Avatar alt={user.displayName} src={user.photoURL} sx={{ marginRight: 1 }} />
            <Typography variant="body1" sx={{ color: '#fff', fontWeight: 'bold', display: { xs: 'none', sm: 'block' } }}>
              {user.displayName}
            </Typography>
            <ArrowDropDownIcon sx={{ color: '#fff', display: { xs: 'none', sm: 'block' } }} />
          </Box>
        )}
        {user && (
          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                marginTop: '8px',
                borderRadius: '8px',
              },
            }}
          >
            <MenuItem onClick={handleProfileOpen} sx={{ color: '#254e86' }}>Profile</MenuItem>
            <MenuItem onClick={handleLogout} sx={{ color: '#254e86' }}>Sign Out</MenuItem>
          </Menu>
        )}
      </Toolbar>

      <Dialog open={profileOpen} onClose={handleProfileClose} PaperProps={{ sx: { padding: 2, backgroundColor: '#f5f5f5' } }}>
        <DialogTitle>
          <Typography variant="h6" sx={{ color: '#254e86', fontWeight: 'bold' }}>Profile</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ textAlign: 'center' }}>
          <Avatar alt={user?.displayName} src={user?.photoURL} sx={{ width: 80, height: 80, margin: '0 auto 20px' }} />
          <DialogContentText sx={{ color: '#333', marginBottom: 1 }}>
            <strong>Name:</strong> {user?.displayName}
          </DialogContentText>
          <DialogContentText sx={{ color: '#333', marginBottom: 1 }}>
            <strong>Email:</strong> {user?.email}
          </DialogContentText>
          <DialogContentText sx={{ color: '#333' }}>
            <strong>Role:</strong> {role || 'No role assigned'}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </AppBar>
  );
}

export default Header;
