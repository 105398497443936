import { createSlice, createAction } from '@reduxjs/toolkit';
import { REHYDRATE } from 'redux-persist';

const rehydrate = createAction(REHYDRATE);

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, error: null, googleToken: null, tokenCreatedTime: null, microsoftToken: null, role: null },
  reducers: {
    userLoginSuccess(state, action) {
      state.user = action.payload.user;
      state.googleToken = action.payload.googleToken;
      state.tokenCreatedTime = action.payload.tokenCreatedTime;
      state.role = action.payload.role;
      state.error = null;
      // console.log("userLoginSuccess: ", state.user);
    },
    userLoginFailure(state, action) {
      state.error = action.payload;
    },
    logout(state) {
      state.user = null;
      state.error = null;
      state.googleToken = null;
      state.tokenCreatedTime = null;
      state.microsoftToken = null;
      state.role = null;
    },
    updateGoogleToken(state, action) {
      state.googleToken = action.payload.googleToken;
      state.tokenCreatedTime = action.payload.tokenCreatedTime;
    },
    updateMicrosoftToken(state, action) {
      state.microsoftToken = action.payload.microsoftToken;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrate, (state, action) => {
      // console.log('Rehydrating:', action.payload); // Log to see what is being rehydrated
      if (action.payload && action.payload.auth) {
        return { ...state, ...action.payload.auth }; // Update state with rehydrated data
      }
    });
  }
});

export const { userLoginSuccess, userLoginFailure, logout, updateGoogleToken, updateMicrosoftToken } = authSlice.actions;
export default authSlice.reducer;
