import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { CUSTOM_TOKEN_ENDPOINT,BASIC_AUTH_TOKEN } from './constants';
import { updateGoogleToken } from '../redux/authSlice';

// Function to fetch a custom token
// Function to fetch a custom token
const fetchCustomToken = async (uid) => {
  // console.log('Fetching custom token for UID:', uid);
  const response = await fetch(CUSTOM_TOKEN_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': BASIC_AUTH_TOKEN, // Add Basic Auth token here
    },
    body: JSON.stringify({ uid }),
  });

  if (!response.ok) {
    console.error('Failed to fetch custom token:', response.statusText);
    throw new Error('Failed to fetch custom token');
  }

  const data = await response.json();
  // console.log('Custom token fetched:', data.customToken);
  return data.customToken;
};

// Function to check if the token is expired
const isTokenExpired = (tokenCreatedTime) => {
  const currentTime = Date.now();
  const tokenAge = currentTime - tokenCreatedTime;
  const tokenValidDuration = 25 * 60 * 1000; // Token is valid for 25 minutes
  return tokenAge >= tokenValidDuration;
};

// Function to redirect to Google Apps
export const redirectToGoogleApps = async (googleUrl, authState, dispatch) => {
  const { googleToken, tokenCreatedTime, user } = authState;

  if (googleToken && !isTokenExpired(tokenCreatedTime)) {
    try {
      const auth = getAuth();
      // console.log('Using stored custom token for sign-in');
      await signInWithCustomToken(auth, googleToken);
      // console.log('Successfully signed in with stored custom token');
      // Open the Google App URL
      const googleAppUrl = `${googleUrl}?authuser=${user.email}`;
      // console.log('Opening Google URL:', googleAppUrl);
      window.open(googleAppUrl, '_blank'); // Open in a new tab
    } catch (error) {
      console.error('Error during signInWithCustomToken with stored token:', error);
      // console.log('Stored token might be expired or invalid, fetching new token.');
      await fetchAndSignInWithNewToken(googleUrl, user.uid, dispatch);
    }
  } else {
    // console.log('No valid stored token found, fetching new token.');
    await fetchAndSignInWithNewToken(googleUrl, user.uid, dispatch);
  }
};

// Helper function to fetch a new token and sign in
const fetchAndSignInWithNewToken = async (googleUrl, uid, dispatch) => {
  try {
    const customToken = await fetchCustomToken(uid);
    // console.log('Fetched new custom token:', customToken);

    const auth = getAuth();
    await signInWithCustomToken(auth, customToken);
    // console.log('Successfully signed in with new custom token');
    // Open the Google App URL
    const googleAppUrl = `${googleUrl}?authuser=${uid}`;
    // console.log('Opening Google URL:', googleAppUrl);
    window.open(googleAppUrl, '_blank'); // Open in a new tab

    // Update the token in the Redux store
    const tokenCreatedTime = Date.now();
    dispatch(updateGoogleToken({ googleToken: customToken, tokenCreatedTime }));
  } catch (error) {
    console.error('Error during fetching new token and sign-in:', error);
  }
};
