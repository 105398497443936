import msalInstance from '../config/msalConfig';
import { updateMicrosoftToken } from '../redux/authSlice';

const officeApps = [
  'https://office.live.com/start/Word.aspx',
  'https://office.live.com/start/Excel.aspx',
  'https://office.live.com/start/PowerPoint.aspx',
];

const liveApps = [
  'https://onedrive.live.com/',
];

const plannerApp = 'https://tasks.office.com/';

const isOfficeApp = (url) => {
  return officeApps.includes(url);
};

const isLiveApp = (url) => {
  return liveApps.includes(url);
};

const isPlannerApp = (url) => {
  return url === plannerApp;
};

const acquireToken = async (scopes) => {
  try {
    const silentTokenRequest = { scopes, account: msalInstance.getAllAccounts()[0] };
    return await msalInstance.acquireTokenSilent(silentTokenRequest);
  } catch (error) {
    return await msalInstance.loginPopup({ scopes });
  }
};

export const redirectToMicrosoftApps = async (microsoftUrl, authState, dispatch) => {
  const { user, microsoftToken } = authState;

  if (!user || !user.uid) {
    throw new Error('User is not authenticated');
  }

  try {
    const loginRequest = {
      scopes: ["user.read", "offline_access", "Group.ReadWrite.All", "Tasks.ReadWrite"],
      // prompt: 'select_account',
      state: JSON.stringify({ redirectUrl: microsoftUrl }),
    };

    if (microsoftToken) {
      if (isOfficeApp(microsoftUrl)) {
        window.open(microsoftUrl, '_blank');
      } else if (isLiveApp(microsoftUrl)) {
        const tokenResponse = await acquireToken(["Files.ReadWrite.All"]);
        if (tokenResponse && tokenResponse.accessToken) {
          dispatch(updateMicrosoftToken({ microsoftToken: tokenResponse.accessToken }));
          window.open(microsoftUrl, '_blank');
        }
      } else if (isPlannerApp(microsoftUrl)) {
        const tokenResponse = await acquireToken(["Group.ReadWrite.All", "Tasks.ReadWrite"]);
        if (tokenResponse && tokenResponse.accessToken) {
          dispatch(updateMicrosoftToken({ microsoftToken: tokenResponse.accessToken }));
          window.open(microsoftUrl, '_blank');
        }
      } else {
        const urlWithToken = `${microsoftUrl}?access_token=${microsoftToken}`;
        window.open(urlWithToken, '_blank');
      }
    } else {
      const loginResponse = await msalInstance.loginPopup(loginRequest);
      if (loginResponse && loginResponse.accessToken) {
        dispatch(updateMicrosoftToken({ microsoftToken: loginResponse.accessToken }));
        if (isOfficeApp(microsoftUrl)) {
          window.open(microsoftUrl, '_blank');
        } else if (isLiveApp(microsoftUrl)) {
          const tokenResponse = await acquireToken(["Files.ReadWrite.All"]);
          if (tokenResponse && tokenResponse.accessToken) {
            window.open(microsoftUrl, '_blank');
          }
        } else if (isPlannerApp(microsoftUrl)) {
          const tokenResponse = await acquireToken(["Group.ReadWrite.All", "Tasks.ReadWrite"]);
          if (tokenResponse && tokenResponse.accessToken) {
            window.open(microsoftUrl, '_blank');
          }
        } else {
          const state = JSON.parse(loginResponse.state);
          const urlWithToken = `${state.redirectUrl}?access_token=${loginResponse.accessToken}`;
          window.open(urlWithToken, '_blank');
        }
      }
    }
  } catch (error) {
    console.error('Error during Microsoft redirection:', error);
  }
};
