// src/pages/Admin.js
import React from 'react';
import { Typography } from '@mui/material';

function Admin() {
  return (
    <div>
      <Typography variant="h4">Admin Page</Typography>
      <p>Welcome to the admin page!</p>
    </div>
  );
}

export default Admin;