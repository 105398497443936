import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import theme from './theme';
import Home from './pages/Home';
import Login from './pages/Login';
import Admin from './pages/Admin';
import Header from './components/Header';
import ProtectedRoute from './components/ProtectedRoute';
import { store, persistor } from './redux/store';
import './App.css';
import AccessManagement from './pages/AccessManagement';
import Unauthorized from './pages/Unauthorized'; // Import Unauthorized page

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Header />
            <div className="App">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<ProtectedRoute requiredRole="client"><Home /></ProtectedRoute>} />
                <Route path="/admin" element={<ProtectedRoute requiredRole="admin"><Admin /></ProtectedRoute>} />
                <Route path="/access-management" element={<ProtectedRoute requiredRole="admin"><AccessManagement /></ProtectedRoute>} />
                <Route path="/unauthorized" element={<Unauthorized />} /> {/* Unauthorized route */}
              </Routes>
            </div>
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
