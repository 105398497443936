import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Box, Divider, Avatar } from '@mui/material';
import { sections, productivityApps, communicationApps, projectManagementApps, timeTrackingApps, administrationApps, customerSupportApps, researchToolsApps, fileManagementApps, virtualAssistantToolsApps, miscellaneousApps } from '../utils/dataMapping';
import { ROLE_LEVELS } from '../utils/roleLevels';
import Carousel from '../components/Carousel';
import { redirectToGoogleApps } from '../utils/redirectToGoogleApps';
import { redirectToMicrosoftApps } from '../utils/redirectToMicrosoftApps';
import Loader from '../components/Loader'; // Import the Loader component
import InfoWindow from '../components/InfoWindow'; // Import InfoWindow
import { updateMicrosoftToken } from '../redux/authSlice';
import msalInstance from '../config/msalConfig';

const highlightColor = '#6a89cc'; // Lighter shade of the header color
const hoverColor = '#f0f0f0'; // Light grey for hover effect
const hoverBorderColor = '#254e86'; // Theme color for hover border

function Home() {
  const [selectedSection, setSelectedSection] = useState('Productivity');
  const [loading, setLoading] = useState(false); // State to control the loader
  const [info, setInfo] = useState({ open: false, message: '', severity: 'info' }); // State for InfoWindow
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const userRole = authState.role || 'unauthorized';

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        setLoading(true); // Show the loader
        await msalInstance.initialize(); 
        setLoading(false); // Hide the loader     
      } catch (error) {
        console.error('Error handling redirect:', error);
        setInfo({ open: true, message: 'Error handling redirect', severity: 'error' }); // Show error message
      }
    };
    initializeMsal();
  }, [dispatch]);

  const handleSectionClick = (section) => {
    setSelectedSection(section);
  };

  const handleAppClick = async (app) => {
    try {
      if (app.url.startsWith('/')) {
        navigate(app.url);
      } else if (app.name.startsWith('Google') || app.name === 'Gmail') {
        setLoading(true); // Show the loader
        await redirectToGoogleApps(app.url, authState, dispatch);
        setLoading(false); // Hide the loader
        
      } else if (app.name.startsWith('Microsoft')) { // Check if the app is a Microsoft app
        setLoading(true); // Show the loader
        await redirectToMicrosoftApps(app.url, authState, dispatch); // Redirect to Microsoft app
        setLoading(false); // Hide the loader
      } else {
        window.open(app.url, '_blank'); // Open non-Google and non-Microsoft URLs in a new tab
      }
    } catch (error) {
      console.error('Error opening app:', error);
      setInfo({ open: true, message: 'Error opening app', severity: 'error' }); // Show error message
      setLoading(false); // Hide the loader
    }
  };

  const getSubMenuItems = () => {
    switch (selectedSection) {
      case 'Productivity':
        return productivityApps;
      case 'Communication':
        return communicationApps;
      case 'Project Management':
        return projectManagementApps;
      case 'Time Tracking':
        return timeTrackingApps;
      case 'Administration':
        return administrationApps;
      case 'Customer Support':
        return customerSupportApps;
      case 'Research Tools':
        return researchToolsApps;
      case 'File Management':
        return fileManagementApps;
      case 'Virtual Assistant Tools':
        return virtualAssistantToolsApps;
      case 'Miscellaneous':
        return miscellaneousApps;
      default:
        return [];
    }
  };

  const handleCloseInfoWindow = () => {
    setInfo({ ...info, open: false });
  };

  const hasAccess = (requiredRole) => {
    return ROLE_LEVELS[userRole] >= ROLE_LEVELS[requiredRole];
  };

  return (
    <Box sx={{ flexGrow: 1, padding: { xs: 1, sm: 2 }, overflowX: 'hidden' }}>
      <Carousel />
      {loading && <Loader />} {/* Conditionally render the Loader */}
      <Grid container spacing={2} sx={{ padding: { xs: 1, sm: 2 }, overflowX: 'hidden' }}>
        <Grid item xs={12} sm={5.5}>
          <Grid container spacing={2}>
            {sections.map((section) => (
              hasAccess(section.requiredRole) && (
                <Grid item xs={12} sm={6} key={section.name}>
                  <Card
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: section.name === selectedSection ? highlightColor : '#fff',
                      boxShadow: section.name === selectedSection ? '0 4px 8px rgba(0, 0, 0, 0.1)' : '0 2px 4px rgba(0, 0, 0, 0.1)',
                      borderRadius: '8px',
                      transition: 'background-color 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        backgroundColor: hoverColor,
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                      },
                    }}
                    onClick={() => handleSectionClick(section.name)}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        align="center"
                        sx={{
                          color: section.name === selectedSection ? '#fff' : '#254e86',
                          fontWeight: 'bold',
                        }}
                      >
                        {section.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Divider orientation="vertical" flexItem sx={{ backgroundColor: '#254e86', width: '2px' }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={5.5}>
          <Typography variant="h5" color="primary" gutterBottom>
            {selectedSection}
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {getSubMenuItems().map((app) => (
              hasAccess(app.requiredRole) && (
                <Grid item xs={6} sm={4} md={3} key={app.name} sx={{ textAlign: 'center' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      cursor: 'pointer',
                      transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
                      borderRadius: '8px',
                      '&:hover > .highlight': {
                        backgroundColor: hoverColor,
                        transform: 'scale(1.05)',
                        boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
                      },
                      '&:active > .highlight': {
                        transform: 'scale(0.95)',
                      },
                    }}
                    onClick={() => handleAppClick(app)}
                  >
                    <Box
                      className="highlight"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: '8px',
                        padding: 1,
                        transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
                      }}
                    >
                      <Avatar
                        src={app.icon}
                        alt={app.name}
                        sx={{
                          width: { xs: 40, sm: 60 },
                          height: { xs: 40, sm: 60 },
                          marginBottom: 1,
                        }}
                      />
                      <Typography variant="body1">
                        {app.name}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )
            ))}
          </Grid>
        </Grid>
      </Grid>
      <InfoWindow
        open={info.open}
        message={info.message}
        severity={info.severity}
        handleClose={handleCloseInfoWindow}
      />
    </Box>
  );
}

export default Home;
