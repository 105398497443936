import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { ROLE_LEVELS } from '../utils/roleLevels';

const ProtectedRoute = ({ children, requiredRole }) => {
  const user = useSelector(state => state.auth.user);
  const role = useSelector(state => state.auth.role);
  const location = useLocation();

  if (!user) {
    // Redirect to the /login page, but save the current location they were trying to go to
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (ROLE_LEVELS[role] < ROLE_LEVELS[requiredRole]) {
    // Redirect to the unauthorized page if the user does not have the required role
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default ProtectedRoute;
