import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const mediaFiles = [
  { type: 'image', src: require('../assets/media/image1.jpg') },
  { type: 'image', src: require('../assets/media/image2.jpg') },
  { type: 'image', src: require('../assets/media/image3.jpg') },
  { type: 'image', src: require('../assets/media/image4.jpg') },
  // Add more media files here
];

function Carousel() {
  const sliderRef = useRef(null);

  const handleBeforeChange = (current, next) => {
    const currentSlide = sliderRef.current?.innerSlider?.list?.children[current];
    if (currentSlide) {
      const currentVideo = currentSlide.querySelector('video');
      if (currentVideo) {
        currentVideo.pause();
      }
    }
  };

  const handleAfterChange = (current) => {
    const currentSlide = sliderRef.current?.innerSlider?.list?.children[current];
    if (currentSlide) {
      const currentVideo = currentSlide.querySelector('video');
      if (currentVideo) {
        currentVideo.play().catch(error => {
          console.error('Error playing video:', error);
        });
      }
    }
  };

  useEffect(() => {
    const firstSlide = sliderRef.current?.innerSlider?.list?.children[0];
    if (firstSlide) {
      const firstVideo = firstSlide.querySelector('video');
      if (firstVideo) {
        firstVideo.play().catch(error => {
          console.error('Error playing video:', error);
        });
      }
    }
  }, []);

  return (
    <Box sx={{ width: '100%', height: '200px', marginBottom: 4 }}>
      <Slider
        dots={true}
        infinite={true}
        speed={1000}  // Transition speed set to 1000ms
        slidesToShow={1}
        slidesToScroll={1}
        autoplay={true}
        autoplaySpeed={10000}  // Increased autoplay speed to 10000ms (10 seconds)
        ref={sliderRef}
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
        arrows={true}  // Enable left and right arrows
      >
        {mediaFiles.map((file, index) => (
          <div key={index}>
            {file.type === 'image' ? (
              <img src={file.src} alt={`media-${index}`} style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
            ) : (
              <video 
                src={file.src} 
                muted 
                loop 
                style={{ width: '100%', height: '200px', objectFit: 'cover' }} 
              />
            )}
          </div>
        ))}
      </Slider>
    </Box>
  );
}

export default Carousel;
