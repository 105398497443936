import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#254e86', // Your theme color
    },
    secondary: {
      main: '#6a89cc', // Secondary color for highlights
    },
    text: {
      primary: '#254e86', // Default text color
      secondary: '#6a89cc',  // Secondary text color for headers
    },
  },
  typography: {
    h6: {
      color: '#254e86', // Header text color
    },
    body1: {
      color: '#254e86', // Default body text color
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            backgroundColor: '#f0f0f0',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          },
          transition: 'background-color 0.3s, box-shadow 0.3s',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h6: {
          color: '#254e86', // Header text color
        },
        body1: {
          color: '#254e86', // Default body text color
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
      },
    },
  },
});

export default theme;
