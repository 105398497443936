import React, { useState } from 'react';
import { signInWithGoogle } from '../firebase/firebase';
import { Button, Typography, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { userLoginSuccess, userLoginFailure } from '../redux/authSlice';
import backgroundImage from '../assets/login_background_2.png';
import Loader from '../components/Loader';
import InfoWindow from '../components/InfoWindow';
import { BASE_URL, BASIC_AUTH_TOKEN } from '../utils/constants';

const StyledPaper = styled(Paper)({
  padding: '40px',
  width: '400px',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  alignItems: 'center',
  background: '#ffffff',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
  borderRadius: '12px',
  backdropFilter: 'blur(10px)',
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginStatus, setLoginStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [infoSeverity, setInfoSeverity] = useState('error');

  const handleGoogleLogin = async () => {
    try {
      setLoading(true);
      const result = await signInWithGoogle();
      if (result) {
        const { user, customToken } = result;
        const userData = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          emailVerified: user.emailVerified,
          photoURL: user.photoURL,
          accessToken: user.stsTokenManager.accessToken,
        };
        const tokenCreatedTime = Date.now();

        try {
          const response = await axios.post(
            `${BASE_URL}/getUserRole`, 
            { email: user.email },
            {
              headers: {
                Authorization: BASIC_AUTH_TOKEN,
              },
            }
          );
          const { role, message } = response.data;
          if (role) {
            dispatch(userLoginSuccess({ user: userData, googleToken: customToken, tokenCreatedTime, role }));
            navigate('/');
          } else {
            setInfoMessage(message || 'Authorization failed: No role assigned to this user');
            setInfoSeverity('error');
            setInfoOpen(true);
            setLoading(false);
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setInfoMessage('User has no role assigned. Please contact Administrator');
          } else {
            setInfoMessage('Authorization failed: ' + (error.response ? error.response.data.error : error.message));
          }
          setInfoSeverity('error');
          setInfoOpen(true);
          setLoading(false);
        }
      } else {
        throw new Error('User is not authorized');
      }
    } catch (error) {
      dispatch(userLoginFailure(error.message));
      setInfoMessage('Authentication failed: ' + error.message);
      setInfoSeverity('error');
      setInfoOpen(true);
      setLoading(false);
    }
  };

  const handleInfoClose = () => {
    setInfoOpen(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
        overflow: 'hidden',
        padding: '20px',
      }}
    >
      {loading && <Loader />}
      <StyledPaper elevation={3}>
        <Typography variant="h5" sx={{ color: '#254e86', fontWeight: 'bold' }} gutterBottom>
          Sign In
        </Typography>
        <Button
          onClick={handleGoogleLogin}
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<GoogleIcon />}
          sx={{
            backgroundColor: '#4285F4',
            color: 'white',
            textTransform: 'none',
            fontWeight: 'bold',
            fontSize: '16px',
            padding: '10px',
            '&:hover': {
              backgroundColor: '#357ae8',
            },
          }}
        >
          Login with GH Virtual
        </Button>
        {loginStatus && (
          <Typography variant="body2" sx={{ color: 'red', marginTop: '10px' }}>
            {loginStatus}
          </Typography>
        )}
      </StyledPaper>
      <InfoWindow
        open={infoOpen}
        message={infoMessage}
        severity={infoSeverity}
        handleClose={handleInfoClose}
      />
    </Box>
  );
};

export default Login;
