import googleDocsLogo from '../assets/google-docs.png';
import googleSheetsLogo from '../assets/google-sheets.png';
import googleSlidesLogo from '../assets/google-slides.png';
import msWordLogo from  '../assets/msword.png';
import msExcelLogo from  '../assets/msexcel.png';
import msPptLogo from  '../assets/msppt.png';

import gmailLogo from  '../assets/gmail.png';
import googleChatLogo from  '../assets/google-chat.png';
import msTeamsLogo from  '../assets/microsoft-teams.png';
import googleMeetLogo from  '../assets/google-meet.png';
import accessMgmtLogo from '../assets/access.png';
import msPlannerLogo from '../assets/msplanner.png';
import googleSchoolarLogo from '../assets/google-scholar.png';

import googleDriveLogo from '../assets/google-drive.png';
import msOneDriveLogo from  '../assets/msonedrive.png';
import googleTranslateLogo  from  '../assets/google-translate.png';
import msOutlookLogo  from  '../assets/msoutlook.png';

export const sections = [
  { name: 'Productivity', requiredRole: 'client' },
  { name: 'Administration', requiredRole: 'admin' },
  { name: 'Communication', requiredRole: 'client' },
  { name: 'Project Management', requiredRole: 'user' },
  { name: 'Time Tracking', requiredRole: 'user' },
  { name: 'Customer Support', requiredRole: 'client' },
  { name: 'Research Tools', requiredRole: 'user' },
  { name: 'File Management', requiredRole: 'client' },
  { name: 'Virtual Assistant Tools', requiredRole: 'user' },
  { name: 'Miscellaneous', requiredRole: 'client' },
];

export const productivityApps = [
  { name: 'Google Docs', icon: googleDocsLogo, url: 'https://docs.google.com/', requiredRole: 'client' },
  { name: 'Google Sheets', icon: googleSheetsLogo, url: 'https://docs.google.com/spreadsheets/', requiredRole: 'client' },
  { name: 'Google Slides', icon: googleSlidesLogo, url: 'https://docs.google.com/presentation/', requiredRole: 'client' },
  { name: 'Microsoft Word', icon: msWordLogo, url: 'https://office.live.com/start/Word.aspx', requiredRole: 'client' },
  { name: 'Microsoft Excel', icon: msExcelLogo, url: 'https://office.live.com/start/Excel.aspx', requiredRole: 'client' },
  { name: 'Microsoft PowerPoint', icon: msPptLogo, url: 'https://office.live.com/start/PowerPoint.aspx', requiredRole: 'client' },
];


export const communicationApps = [
  { name: 'Gmail', icon: gmailLogo, url: 'https://mail.google.com/', requiredRole: 'client' },
  { name: 'Google Chat', icon: googleChatLogo, url: 'https://chat.google.com', requiredRole: 'client' },
  { name: 'Google Meet', icon: googleMeetLogo, url: 'https://meet.google.com/', requiredRole: 'client' },
  { name: 'Microsoft Teams', icon: msTeamsLogo, url: 'https://teams.microsoft.com/', requiredRole: 'client' },
  { name: 'Microsoft Outlook', icon: msOutlookLogo, url: 'https://outlook.office.com/', requiredRole: 'client' },
];

export const projectManagementApps = [

];

export const timeTrackingApps = [
];

export const administrationApps = [
  { name: 'Access Management', icon: accessMgmtLogo, url: '/access-management', requiredRole: 'admin' },
];

export const customerSupportApps = [
];

export const researchToolsApps = [
];

export const fileManagementApps = [
  { name: 'Google Drive', icon: googleDriveLogo, url: 'https://drive.google.com/', requiredRole: 'client' },
  { name: 'Microsoft OneDrive', icon: msOneDriveLogo, url: 'https://onedrive.live.com/', requiredRole: 'client' },
];

export const virtualAssistantToolsApps = [
];

export const miscellaneousApps = [
  { name: 'Google Translate', icon: googleTranslateLogo, url: 'https://translate.google.com/', requiredRole: 'client' },
];
