import { PublicClientApplication } from '@azure/msal-browser';

//dev
const msalConfig = {
  auth: {
    clientId: '73e7f15e-44aa-4c8d-b2a2-c64e3fc33635',
    authority: 'https://login.microsoftonline.com/db341391-78f6-4590-95a6-b438c7fefb80',
    redirectUri: 'https://ghvirtualdev-3d38d.web.app/', // Update with your app's redirect URI
  },
  system: {
    allowNativeBroker: true,
  },
};

//prod

const msalConfigProd = {
  auth: {
    clientId: 'c4ccdd4e-2be7-4423-af5e-cc18051f5a58',
    authority: 'https://login.microsoftonline.com/98a74e13-1efd-4e2e-af04-dbfc00367307',
    redirectUri: 'https://portal.ghvirtual.com/', // Update with your app's redirect URI
  },
  system: {
    allowNativeBroker: true,
  },
};

const msalInstance = new PublicClientApplication(msalConfigProd);

export default msalInstance;
