import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const AddUserDialog = ({ open, user, onClose, onSave, onChange }) => {
  const [emailError, setEmailError] = useState('');

  const handleFieldChange = (field, value) => {
    if (field === 'email') {
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      const endsWithGhVirtual = value.endsWith('@ghvirtual.com');
      setEmailError(
        isValidEmail && endsWithGhVirtual ? '' : 'Invalid email format or domain'
      );
    }
    onChange(field, value);
  };

  const handleSave = () => {
    if (!emailError) {
      onSave();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please fill in the details to add a new user.
        </DialogContentText>
        <TextField
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={user.email}
          onChange={(e) => handleFieldChange('email', e.target.value)}
          error={!!emailError}
          helperText={emailError}
        />
        <TextField
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          value={user.displayName}
          onChange={(e) => handleFieldChange('displayName', e.target.value)}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Role</InputLabel>
          <Select
            value={user.role}
            onChange={(e) => handleFieldChange('role', e.target.value)}
            label="Role"
          >
            <MenuItem value="unauthorized">Unauthorized</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="client">Client</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" startIcon={<CancelIcon />}>
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" startIcon={<SaveIcon />}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;
