import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button, Typography, Paper, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Select, MenuItem, TextField, IconButton, Tooltip, InputAdornment
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Loader from '../components/Loader';
import InfoWindow from '../components/InfoWindow';
import AddUserDialog from './dialogs/AddUserDialog';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog';
import { BASE_URL } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AccessManagement = () => {
  const [users, setUsers] = useState([]);
  const [editedUsers, setEditedUsers] = useState({});
  const [originalUsers, setOriginalUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('');
  const [info, setInfo] = useState({ open: false, message: '', severity: 'info' });
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [newUser, setNewUser] = useState({ email: '', displayName: '', role: 'unauthorized' });
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const navigate = useNavigate();
  // const googleToken = useSelector((state) => state.auth.googleToken); // Access the Google token from Redux state
  const accessToken = useSelector((state) => state.auth.user.accessToken); 

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/getUsers`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setUsers(response.data);
      setOriginalUsers(response.data);
      setLoading(false);
    } catch (error) {
      setError('Error fetching users');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleRoleChange = (email, newRole) => {
    setEditedUsers({
      ...editedUsers,
      [email]: newRole
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const updates = Object.keys(editedUsers).map((email) =>
        axios.post(`${BASE_URL}/updateUserRole`, { email, role: editedUsers[email] }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      );

      await Promise.all(updates);
      setInfo({ open: true, message: 'Roles updated successfully!', severity: 'success' });
      setEditedUsers({});
      setLoading(false);
      fetchUsers(); // Refetch users to reflect changes
    } catch (error) {
      console.error('Error updating roles:', error);
      setInfo({ open: true, message: 'Error updating roles', severity: 'error' });
      setLoading(false);
    }
  };


  const handleCancel = () => {
    fetchUsers(); // Refetch users to reset changes
    setEditedUsers({});
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredUsers = users
    .filter(user => user.role !== 'super-admin')
    .filter(user =>
      user.displayName.toLowerCase().includes(filter.toLowerCase()) ||
      user.email.toLowerCase().includes(filter.toLowerCase())
    );

  const handleCloseInfoWindow = () => {
    setInfo({ ...info, open: false });
  };

  const handleAddClick = () => {
    setNewUser({ email: '', displayName: '', role: 'unauthorized' });
    setAddDialogOpen(true);
  };

  const handleAddChange = (field, value) => {
    setNewUser(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleAddSave = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/addUser`, newUser, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setInfo({ open: true, message: response.data.message, severity: 'success' });
      setAddDialogOpen(false);
      setLoading(false);
      fetchUsers(); // Refetch users to reflect changes
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : 'An error occurred while adding the user.';
      console.error('Error adding user:', error);
      setInfo({ open: true, message: errorMessage, severity: 'error' });
      setLoading(false);
    }
  };
  

  const handleDeleteClick = (user) => {
    setUserToDelete(user);
    setConfirmDeleteOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      await axios.post(`${BASE_URL}/deleteUser`, { email: userToDelete.email }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setInfo({ open: true, message: 'User deleted successfully!', severity: 'success' });
      setConfirmDeleteOpen(false);
      setLoading(false);
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
      setInfo({ open: true, message: 'Error deleting user', severity: 'error' });
      setLoading(false);
    }
  };


  const handleDeleteCancel = () => {
    setConfirmDeleteOpen(false);
    setUserToDelete(null);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding={3} height="100vh" sx={{ backgroundColor: '#f5f5f5' }}>
      {loading && <Loader />}
      <Box display="flex" alignItems="center" width="100%" maxWidth="1200px" marginBottom={2}>
        <IconButton onClick={() => navigate('/')} sx={{ marginRight: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" sx={{ flexGrow: 1 }}>Access Management</Typography>
        <Button variant="contained" color="primary" onClick={handleAddClick} startIcon={<AddIcon />}>
          Add User
        </Button>
      </Box>
      <Paper elevation={3} style={{ padding: '20px', width: '100%', maxWidth: '1200px', overflow: 'hidden', marginBottom: '20px' }}>
        <Box display="flex" justifyContent="space-between" marginBottom={2}>
          <TextField
            label="Search by Name or Email"
            variant="outlined"
            fullWidth
            value={filter}
            onChange={handleFilterChange}
            sx={{ marginBottom: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {error && <Typography color="error">{error}</Typography>}
        <TableContainer sx={{ maxHeight: '60vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Role</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map(user => (
                <TableRow key={user.email}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.displayName}</TableCell>
                  <TableCell>
                    <Select
                      value={editedUsers[user.email] !== undefined ? editedUsers[user.email] : user.role || "unauthorized"}
                      onChange={(e) => handleRoleChange(user.email, e.target.value)}
                      displayEmpty
                      sx={{ minWidth: 150 }}
                    >
                      <MenuItem value="unauthorized">Unauthorized</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="user">User</MenuItem>
                      <MenuItem value="client">Client</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleDeleteClick(user)} sx={{ color: '#f44336' }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box display="flex" justifyContent="center" gap={2} marginTop={2} width="100%" maxWidth="1200px">
        <Button variant="contained" color="primary" onClick={handleSave} startIcon={<SaveIcon />}>
          Save
        </Button>
        <Button variant="contained" style={{ backgroundColor: '#f44336', color: '#fff' }} onClick={handleCancel} startIcon={<CancelIcon />}>
          Cancel
        </Button>
      </Box>
      <InfoWindow
        open={info.open}
        message={info.message}
        severity={info.severity}
        handleClose={handleCloseInfoWindow}
      />
      <AddUserDialog
        open={addDialogOpen}
        user={newUser}
        onClose={() => setAddDialogOpen(false)}
        onSave={handleAddSave}
        onChange={handleAddChange}
      />
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
    </Box>
  );
};

export default AccessManagement;
