import { initializeApp } from 'firebase/app';
import {
  getAuth,
  connectAuthEmulator,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import {
  getFirestore,
  connectFirestoreEmulator,
} from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { DEV_FIREBASE_CONFIG, PROD_FIREBASE_CONFIG, CUSTOM_TOKEN_ENDPOINT, BASIC_AUTH_TOKEN } from '../utils/constants';

const app = initializeApp(PROD_FIREBASE_CONFIG);
const functions = getFunctions(app);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

const fetchCustomToken = async (uid) => {
  const response = await fetch(CUSTOM_TOKEN_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': BASIC_AUTH_TOKEN, // Add Basic Auth token here
    },
    body: JSON.stringify({ uid }),
  });

  if (!response.ok) {
    console.error('Failed to fetch custom token:', response.statusText);
    throw new Error('Failed to fetch custom token');
  }

  const data = await response.json();
  return data.customToken;
};

const signInWithGoogle = async () => {
  try {
    googleProvider.setCustomParameters({
      hd: 'ghvirtual.com', // Specify the hosted domain
      prompt: 'select_account' // Force account selection
    });
    const result = await signInWithPopup(auth, googleProvider);

    if (result.user) {
      if (result.user.email && result.user.email.endsWith('@ghvirtual.com')) {
        const user = result.user;

        // Fetch the custom token from the token generator
        const customToken = await fetchCustomToken(user.uid);

        // Return both user and custom token
        return { user, customToken };
      } else {
        console.error('Login not allowed from this domain.');
        await signOut(auth); // Log out the user immediately
        throw new Error('Please sign in using your GH Virtual email address.');
      }
    } else {
      console.log('No user returned from signInWithPopup');
      return false;
    }
  } catch (err) {
    console.error('Error during signInWithGoogle:', err);
    throw err;
  }
};

const sendPasswordReset = async (email) => {
  try {
    alert('Password reset link sent!');
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export { auth, db, functions, logout, signInWithGoogle, sendPasswordReset };
