export const DEV_FIREBASE_CONFIG = {
    apiKey: "AIzaSyBfaUNJuzfSFx1cIHQg5mU0D58PElSYpEU",
    authDomain: "ghvirtualdev-3d38d.firebaseapp.com",
    projectId: "ghvirtualdev-3d38d",
    storageBucket: "ghvirtualdev-3d38d.appspot.com",
    messagingSenderId: "934852899121",
    appId: "1:934852899121:web:0213fa0f1cc45bca5c8010",
    measurementId: "G-PGCK5QPH6C"
  };
  
  export const PROD_FIREBASE_CONFIG = {
    apiKey: "AIzaSyC722A6kQKb4Cua5e3kQytsrURym95TkU8",
    authDomain: "gh-virtual-prod.firebaseapp.com",
    projectId: "gh-virtual-prod",
    storageBucket: "gh-virtual-prod.appspot.com",
    messagingSenderId: "473804529338",
    appId: "1:473804529338:web:18c876181a74e9b071ac83",
    measurementId: "G-KXEZBWWY4B"
  };
  
//DEV
// export const CUSTOM_TOKEN_ENDPOINT = 'https://us-central1-ghvirtualdev-3d38d.cloudfunctions.net/generateCustomToken';
// export const MICROSOFT_TOKEN_ENDPOINT = 'https://us-central1-ghvirtualdev-3d38d.cloudfunctions.net/authMicrosoft';
// export const BASE_URL = 'https://us-central1-ghvirtualdev-3d38d.cloudfunctions.net';
// export const BASIC_AUTH_TOKEN = "Basic d2V0endldHpAZnVja3lvdWFyb246ZnVja3lvdWFyb24="

//PROD
export const CUSTOM_TOKEN_ENDPOINT = 'https://us-central1-gh-virtual-prod.cloudfunctions.net/generateCustomToken';
export const MICROSOFT_TOKEN_ENDPOINT = 'https://us-central1-gh-virtual-prod.cloudfunctions.net/authMicrosoft';
export const BASE_URL = 'https://us-central1-gh-virtual-prod.cloudfunctions.net';
export const BASIC_AUTH_TOKEN = "Basic d2V0endldHpAZnVja3lvdWFyb246ZnVja3lvdWFyb24="
